import React from 'react'

function H1({ children, styles }) {
  return (
    <h1 className={` text-3xl font-extrabold my-4 uppercase ${styles}`}>
        {children}
    </h1>
  )
}

export default H1