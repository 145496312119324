import { Avatar } from '@chakra-ui/react'
import { IconBuilding, IconHammer, IconHome } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button2 from '../components/buttons/Button'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import '../css/textEditorCSS.css'
import supabase from '../supabase/connect'
const parser = new DOMParser();

const HtmlContentDisplay = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};


function SingleListingPage() {
    const {listing_id} = useParams()
    const [info, setInfo] = useState('')
    const [realtor, setRealtor] = useState('')

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2 // Specifies the number of decimal places
    })
    const formattedAmount = formatter.format(info ? info.price : 0)
    const listing_info = async() => {
      let { data: listing, error } = await supabase
      .from('listing')
      .select('*')
      .eq('id', listing_id)
      if (error) {
        console.error(error)
        return
      }
      let { data: user, error: user_err } = await supabase
      .from('User')
      .select('*, personal_page(custom_url)')
      .eq('user_id', listing[0].user_id)
      if (user_err) {
        console.error(error)
        return
      }
      setInfo(listing[0])
      setRealtor(user[0])
    }
    const show_desc = (str) => {
      const doc = parser.parseFromString(str, 'text/html')
      const bodyContent = doc.body.innerHTML;
      return bodyContent
    }
    useEffect(() => {
      listing_info()
    },[])
    if (!info || !realtor){
      return <Loader />
    }
  return (
<SectionSetUp styles='min-h-fit-screen max-w-[1200px] mx-auto !justify-start'>
  <section className='flex flex-wrap md:flex-nowrap border rounded-lg p-4 bg-white shadow gap-5 w-full w-full justify-between items-start'>
      <img className='w-full md:w-[60%] max-w-[600px] object-cover rounded-lg shadow ' src={info.images[0]} alt='img'/>
      <div className='flex flex-col justify-start items-start gap-3 md:w-[40%] '>
        <span className='text-4xl font-extrabold'>{formattedAmount}</span>
        <span className='text-xl '>{info.address}</span>
        <div className=' border rounded-lg bg-gray-100 flex flex-col items-center justify-center p-5'>
          <Button2 styles='bg-primary hover:bg-primary/[0.9] text-white text-xl'>Contact {info.realtor}</Button2>
        </div>
      </div>
  </section>
  <section className='w-full flex bg-white shadow-lg rounded-lg p-4 mt-1 gap-3 items-center justify-around'>
    <div className='flex justify-start items-start'>
      <span className='font-extrabold text-3xl md:text-4xl'>{info.bedrooms}</span>
      <span>beds</span>
    </div>
    <div className='flex justify-start items-start'>
      <span className='font-extrabold text-3xl md:text-4xl'>{info.baths}</span>
      <span>baths</span>
    </div>
    <div className='flex justify-start items-start'>
      <span className='font-extrabold text-3xl md:text-4xl'>{info.sq_feet}</span>
      <span>sqft</span>
    </div>
    <div className='flex justify-start items-start'>
      <span className='font-extrabold text-3xl md:text-4xl'>{info.acres}</span>
      <span>acres</span>
    </div>
  </section>
  <section className='w-full flex flex-wrap gap-3  p-1'>
    <span className='flex border border-primary rounded p-2 text-primary font-bold gap-1 bg-white'><IconHammer /> Built: {info.year_built}</span>
    <span className='flex border border-primary rounded p-2 text-primary font-bold gap-1 bg-white'><IconBuilding /> {info.type}</span>
    <span className='flex border border-primary rounded p-2 text-primary font-bold gap-1 bg-white'><IconHome /> HOA: ${info.HOA ? info.HOA : 0}</span>
  </section>
  <section className='w-full items-start justify-start p-6 bg-white shadow-lg mb-1 rounded-lg text-xl'>
  <HtmlContentDisplay htmlContent={show_desc(info.description)} />
  </section>
  <section className=' auto-rows-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1'>
    {
      info.images.map((each,index) => {
        return <img key={index} className='w-full h-full object-cover' src={each} alt='img'/>
      })
    }
  </section>
  <section className='mt-1 flex gap-4 items-center  border border-primary bg-white rounded-lg shadow-lg p-4'>
    <Avatar size='xl' name={info.realtor} src={realtor.profilePic} />
    <div className='flex flex-col gap-1 items-start justify-start'>
      <span className='uppercase font-extrabold'>
        {info.agency_name}
      </span>
      <Link to={`/${realtor.personal_page[0].custom_url}`} className='font-bold text-primary underline'>
        {info.realtor}
      </Link>
      <div className=' border rounded-lg bg-gray-100 flex flex-col items-center justify-center p-3 md:p-5'>
          <Button2 styles='bg-primary hover:bg-primary/[0.9] text-white text-md md:text-lg'>Contact {info.realtor}</Button2>
      </div>

    </div>
  </section>
  <div className='w-full flex gap-4 items-center justify-start bg-white p-4 rounded-lg shadow-lg my-1'>
      <img className='w-24 h-24' src='https://cnkmivpinfagdnsaxcdk.supabase.co/storage/v1/object/public/profilePics/public/Fair%20Housing%20Logo/equal-housing-opportunity-logo-1200w%20(1).jpg' alt='equal housing opportunity'/>
      <p className='text-xs'>**The Fair Housing Division of the Georgia Commission on Equal Opportunity is committed to eradicating unlawful discrimination throughout the State of Georgia in housing. It is the mission of the Fair Housing Division to promote broader housing choices in Georgia, to promote understanding of the Georgia Fair Housing Law, to encourage integrated communities and/or neighborhoods, to secure compliance with state and federal fair housing laws and to establish justice and equal opportunity for all persons whose housing rights have been violated.</p>
  </div>
</SectionSetUp>
  )
}

export default SingleListingPage