import React from 'react';

// Define Card.Item
export const CardItem = ({ children  }) => {
  return <div className='w-full flex justify-center items-center p-1 my-3 text-center' >{children}</div>;
};

// Define Card and attach Card.Item as a property
export const Card = ({ children }) => {
  return (
    <div className='flex grow flex-col w-full max-w-[375px] justify-start items-center p-4 bg-white shadow rounded-lg'>
        {children}
    </div>
);
};
Card.Item = CardItem;


