import { Input } from '@chakra-ui/react'
import { IconCamera, IconUser } from '@tabler/icons-react'
import React from 'react'
import supabase from '../../supabase/connect'
import Button2 from '../buttons/Button'
function SectionOne({ user, userData }) {
    const fileInputRef = React.createRef()
    const handleFileInputClick = () => {
        // Programmatically click the hidden file input when the button is clicked
        fileInputRef.current.click();
    };
    const filterPics = async (userId) =>{
        const { data, error } = await supabase
            .storage
            .from('profilePics')
            .list(`public/${userId}`, { limit: 100 }); // Adjust path and limit as needed
        if (error) {
            console.error('Error listing images:', error.message);
            return [];
        }

        return data;
    }
    const deleteUserOldImages = async(userId, currentImageUrl) => {
        const allImages = await filterPics(userId);
        const currentImageKey = currentImageUrl.split('/').pop(); // Extract the file name from URL
        const imagesToDelete = allImages
            .filter(image => image.name !== currentImageKey) // Filter out the current image
            .map(image => `public/${userId}/${image.name}`); // Prepare full paths
        if (imagesToDelete.length > 0) {
            const { data: del_data, error: del_error } = await supabase
                .storage
                .from('profilePics')
                .remove(imagesToDelete);
    
            if (del_error) {
                console.error('Error deleting images:', del_error.message);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }
    const uploadPic = async (file) => {
        let date = Date.now()
        const { data, error } = await supabase
        .storage
        .from('profilePics')
        .upload(`public/${user.id}/${user.id}-${date}.jpg`, file, {
            cacheControl: '3600',
            upsert: true
        })
        if(error){
            alert(error.message)
            return
        }
        const { data: public_url } = supabase
        .storage
        .from('profilePics')
        .getPublicUrl(`public/${user.id}/${user.id}-${date}.jpg`)
        const { data:url, error: url_err } = await supabase
        .from('User')
        .update({ profilePic: public_url.publicUrl })
        .eq('user_id', user.id)
                
        if(url_err){
            alert(url_err.message)
            return
        }
        await deleteUserOldImages(user.id, `public/${user.id}/${user.id}-${date}.jpg`)
        window.location.reload()
    }
    const handleFileChange = (event) => {
        // Handle file selected
        const file = event.target.files[0];
        uploadPic(file)
    };
  return (
    <div className='bg-gray-400 text-white rounded-lg shadow-lg flex items-center justify-center relative overflow-hidden w-[12rem] h-[12rem] flex justify-center items-center'>
        {
            userData.profilePic ? <img className=' rounded-lg shadow-lg w-full h-full' src={userData.profilePic} alt='profile-pic'/> : <IconUser width={90} height={90} />
        }
        <div className='opacity-0 absolute bg-white/[0.6] hover:opacity-100 top-0 right-0 left-0 bottom-0 flex justify-center items-center'>
            <Button2 onClick={handleFileInputClick}>
                    <IconCamera className='text-gray-400' width={45} height={45} />
            </Button2>
            <Input ref={fileInputRef} onChange={handleFileChange} type='file' className='hidden' />
        </div>
    </div>
  )
}

export default SectionOne