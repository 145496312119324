import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/navigation/Footer'
import Navbar from '../components/navigation/Navbar'
function Root() {
  return (
    <ChakraProvider>
      <main className='w-full flex flex-col bg-gray-100 min-h-screen overflow-y-auto'>
          <Navbar />
          <Outlet />
          <Footer />
      </main>
    </ChakraProvider>
  )
}

export default Root