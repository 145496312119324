import { Avatar, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { IconMenu } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Auth from '../../auth/Auth'
import supabase from '../../supabase/connect'
import { NavLogout } from '../buttons/LogOutBTNS'
import routes from '../dashboard/navRoutes'
function Navbar() {
  const { form_id } = useParams()
  const [user, setUser] = useState()
  const [userData, setUserData] = useState()
  const [path, setPath] = useState('')
  const userInfo = async () => {
    const info = await Auth.userInfo()
    if (!info){
        setUser(false)
        return
    }
    let { data: User, error } = await supabase
    .from('User')
    .select('profilePic, first_name, last_name')
    .eq('user_id', info.id)
    if(error){
        console.log(error.message)
    }
    setUser(info)
    setUserData(User[0])
    setPath(window.location.pathname)
}
  useEffect(() => {
    userInfo()
  }, [])
  return (
    <nav className={`w-full p-4 ${path === `/openhouseform/listing/${form_id}` ? 'hidden' : 'flex'} justify-between items-center `}>
        <Link to='/'><h1 className='text-primary font-bold text-md md:text-2xl '>AGENT BUDDY</h1></Link>

        <div className=' flex h-full items-center gap-2'>
          <Menu>
              <MenuButton
                aria-label='Nav Menu'
              >
                <div className='bg-gray-100 border p-1 rounded-lg'><IconMenu className='text-primary' /></div>
              </MenuButton>
              <MenuList>
                <MenuItem >
                  <Link className='w-full' to='/calculators'>Calculators</Link>
                </MenuItem>
                <MenuItem>
                  <Link className='w-full' to='/calculators'>Blog</Link>
                </MenuItem>
                {
                  user ?  <MenuDivider /> : <></>
                }
                
                  {
                    user ? routes.map((each, index) => {
                      return <MenuItem key={index} ><Link className='w-full' to={each.route}>{each.tooltip}</Link></MenuItem>
                    }) : <></>
                  }
                
                <MenuDivider />
                
                  {
                    user ? <MenuItem><Link className='w-full text-primary font-bold'  to={`/user/dashboard`}>Dashboard</Link></MenuItem> : <></>
                  }
                
                <MenuItem>
                  {
                    user ? <NavLogout /> : <Link className='w-full text-primary font-bold' to='/login' >Login/Signup</Link>
                  }
                </MenuItem>

              </MenuList>
          </Menu>
          {
            userData && userData.profilePic ? <Link to={`/settings`}><Avatar size='sm' name={`${userData.first_name} ${userData.last_name}`} src={userData.profilePic}/></Link> : <Link className='w-full text-primary font-bold' to='/login' >Login/Signup</Link>
          }
        </div>
    </nav>
  )
}

export default Navbar