import {
    Alert,
    AlertIcon,
    AlertTitle
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { alertTimer } from './AlertTimer'

function CustomAlert({ message }) {
    const [alert, setAlert] = useState('hidden')
    useEffect(() => {
        if(message.message === ''){
            return
        }
        alertTimer(setAlert, true, 5000)
    }, [message])
  return (
    <div className={`${alert} top-20 right-[1rem] rounded-lg shadow overflow-hidden animate-pulse duration-1000 transition`}>
        <Alert status={message.bool ? 'success' : 'error'}>
            <AlertIcon />
            <AlertTitle>{message.message}</AlertTitle>
        </Alert>
    </div>

  )
}

export default CustomAlert