import supabase from "../../supabase/connect"

export const saveInfo = async (id, obj) => {
    
    const { data, error } = await supabase
    .from('personal_page')
    .update(obj)
    .eq('user_id', id)

    if (error && error.code === '23505'){
        console.error(error)
        return {
            str: 'URL is already taken',
            bool: false
        }
    } else if (error) {
        return {
            str: 'Failed to save. Try again later.',
            bool: false
        }
    }
    return {
        str: 'Saved Successfully',
        bool: true
    }
}