import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // or quill.bubble.css for bubble theme

function QuillText({ val, setVal }) {
  return (
    <div className='w-full my-4 max-w-[700px]'>
        <ReactQuill theme="snow" value={val} onChange={setVal} />
    </div>
  )
}

export default QuillText