import { IconPlus } from '@tabler/icons-react'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import Button2 from '../buttons/Button'
export default function ListingimgDrop ({ images, setImages }) {
return (
<>
    <Dropzone onDrop={acceptedFiles => setImages(currentItems => [...currentItems, acceptedFiles[0]])}>
    {({getRootProps, getInputProps}) => (
        <section className='border border-dashed p-3 rounded-lg w-full flex flex-col justify-center items-center'>
            <span className='text-lg font-bold'>Drop Listing Images Here</span>
            <span className='text-sm'>or</span>
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button2 type='button' styles='bg-primary hover:bg-primary/[0.9] text-white'>Click Here</Button2>
        </div>
        <div className='flex flex-wrap gap-2'>
            {
                images ? images.map((each, index) => {
                    return <span key={index}>{each.path}</span>
                }) : <></>
            }
        </div>
        </section>
    )}
    </Dropzone>
</>
)

}