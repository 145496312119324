import React, { useState } from 'react';
import Button2 from './Button';
import supabase from '../../supabase/connect';
import { IconDownload, IconLock } from '@tabler/icons-react';
import CustomAlert from '../alerts/CustomAlert'
function DownloadCSVButton({ user, userData }) {
    console.log(userData)
    const [message, setMessage] = useState({
        message: '',
        bool: ''
    })
    const now = new Date(); // Creates a new Date object with the current date and time
    const month = now.getMonth(); // Returns the month (0-11, where 0 is January and 11 is December)
    const year = now.getFullYear(); // Returns the full year (e.g., 2024)
    
    const downloadCSV = (csvData, filename = `contactsASof${month+1}${year}.csv`) => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleDownload = async() => {
        const { data, error } = await supabase
        .from('contact')
        .select('first_name, last_name, email, phone')
        .eq('user_id', user)
        .csv()
        if (error) {
            console.error(error)
        }
        downloadCSV(data)
    }

    return (
        <>
        <CustomAlert message={message} />
        <Button2 styles='bg-primary text-white hover:bg-primary/[0.9]' onClick={() => {
            if (userData === 'basic') {
                setMessage({
                    message: 'You need to upgrade your plan to access this feature.',
                    bool: false
                })
                return <CustomAlert />
            }
            handleDownload()
        }}>
            
            {
                userData === 'basic' ? <div className='text-white flex items-center gap-1'><IconLock className='text-red' /><IconDownload /> CSV</div> : <div className='text-white flex items-center gap-1'><IconDownload /> CSV</div>
            }
        </Button2>
        </>
    );
}

export default DownloadCSVButton;
