import React from 'react'

function Loader() {
  return (
    <div className='min-h-screen flex justify-center items-center'>
        <h1 className='animate-pulse text-primary text-xl font-extrabold'>Loading...</h1>
    </div>
  )
}

export default Loader