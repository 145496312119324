import {
    Alert,
    AlertIcon,
    AlertTitle,
    Text
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Auth from '../auth/Auth'
import Button2 from '../components/buttons/Button'
import Drawer1 from '../components/contactBookPage/Drawer'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import supabase from '../supabase/connect'
import formatPhone from '../utils/formatPhone'
import ContactCard from '../components/contactBookPage/ContactCard'
import DownloadCSVButton from '../components/buttons/DownloadCSVButton'
function ContactBook() {
    const [user, setUser] = useState()
    const [upper, setUpper] = useState(20)
    const [lower, setLower] = useState(0)
    const [userData, setUserData] = useState()
    const [user_info, setUser_info] = useState('')
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
            return
        }
        let { data: UserInfo, error3 } = await supabase
        .from('User')
        .select('subscription_lvl')
        .eq('user_id', info.id)
        if(error3){
            console.log(error3.message)
        }
        let { data: User, error } = await supabase
        .from('contact')
        .select('*')
        .eq('user_id', info.id)
        .range(lower,upper)
        if(error){
            console.log(error.message)
        }
        setUser(info)
        let sorted_arr = User.sort((a, b) => {
            const lastNameA = a.last_name.toLowerCase();
            const lastNameB = b.last_name.toLowerCase();
            if (lastNameA < lastNameB) {
                return -1;
              }
              if (lastNameA > lastNameB) {
                return 1;
              }
              return 0;

        })
        setUserData(sorted_arr)
        setUser_info(UserInfo)
    }
    useEffect(() => {
      userInfo()
    }, [upper,lower])
    if(!user || !userData){
        return <Loader />
    }
  return (
    <SectionSetUp styles='min-h-screen !justify-start mt-12'>
        <section>
            <DownloadCSVButton user={user.id} userData={user_info[0].subscription_lvl}/>
        </section>

        <section className=' flex justify-center items-center fixed bottom-6 left-6 shadow z-50'>
            <Drawer1 user={user} reGet={userInfo} />
        </section>
        <section className='grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-3'>
            {
                userData.map((each, index) => {
                    return (
                        <div key={each.id} >
                            <ContactCard info={each} />
                        </div>
                    )
                })
            }
        </section>
        <div className={`flex gap-3 my-3 `}>
            <Button2 onClick={() => {
                setLower(lower - 20)
                setUpper(upper - 20)
            }} styles={`${lower <= 0 ? '!hidden' : '!flex'} hover:bg-primary/[0.9] text-white`} color='bg-primary'>
                Prev
            </Button2>
            <Button2 onClick={() => {
                setLower(lower + 20)
                setUpper(upper + 20)
            }} styles={`${userData.length <= 20 ? '!hidden' : '!flex'} hover:bg-primary/[0.9] text-white`} color='bg-primary'>
                Next
            </Button2>
        </div>
    </SectionSetUp>

  )
}

export default ContactBook