import { IconMountain } from '@tabler/icons-react'
import React from 'react'

function HeroSection({ data }) {

    const { hero_img } = data.data
  return (
    <header className='w-full relative h-[300px] md:h-[600px]'>
        {
            hero_img ? <img src={hero_img} alt='hero-img' className='object-fill w-full h-full' /> : <div className='bg-gray-300 text-white flex justify-center items-center p-8 w-full h-full '><IconMountain height={75} width={75} /></div>
        }
    </header>
  )
}

export default HeroSection