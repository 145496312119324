import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import H1 from '../headers/H1'

function Footer() {
  const {form_id} = useParams()
  const [path, setPath] = useState(window.location.pathname)

  return (
    <footer className={`${path === `/openhouseform/listing/${form_id}` ? 'hidden' : 'flex'} w-full flex-col justify-center items-center bg-white`}>
        <div className='w-full flex justify-center items-center'>
            <H1 styles='text-primary'>
                AGENT BUDDY
            </H1>
        </div>
        <div className='text-secondary flex gap-3 my-3 w-full justify-center items-center'>
            <span className='underline cursor-pointer'>Terms of Service</span>
            <span className='underline cursor-pointer'>Cookie Policy</span>
        </div>
    </footer>
  )
}

export default Footer