import React from 'react'
import ConnectSection from '../components/landing/ConnectSection'
import HeroSection from '../components/landing/HeroSection'
import PricingSection from '../components/landing/PricingSection'
import SectionSetUp from '../components/sections/SectionSetUp'

function Landing() {
  return (
    <SectionSetUp>
        <HeroSection />
        <ConnectSection />
        <PricingSection />
        {/* <ManageSection /> */}
    </SectionSetUp>
  )
}

export default Landing