import { Tooltip } from '@chakra-ui/react'
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTiktok, IconBrandX, IconHome, IconSpeakerphone, IconUser } from '@tabler/icons-react'
import React from 'react'
import { Link } from 'react-router-dom'

function SocialsSection({ data }) {
    const { profile_pic, first_name, last_name } = data
    const { linkedin_link, tiktok_link, broker_link, facebook_link, insta_link, page_slogan, x_link, agency_name } = data.data

    return (
        <section className='flex flex-col w-full justify-center items-center relative'>
            <div className='flex flex-wrap items-center p-8 w-full gap-5 justify-center'>
                {
                    profile_pic ? <img className=' rounded-lg shadow-lg w-[12rem] h-[12rem]' src={profile_pic} alt='profile-pic'/> : <div className='bg-gray-300 w-[12rem] h-[12rem] rounded-lg flex justify-center items-center shadow-lg'><IconUser /></div>
                }
                    
                    <div className='w-full max-w-[600px] flex flex-col gap-4 items-center justify-center md:items-start'>
                        <span className='text-4xl font-bold uppercase'>{agency_name}</span>
                        <Tooltip label='Slogan' aria-label='slogan tooltip'>
                            <div className='flex gap-2 bg-gray-200 w-full p-1 rounded font-bold items-center'>
                                <IconSpeakerphone className='text-primary' />
                                <span className='text-xl'>{page_slogan}</span>
                            </div>
                        </Tooltip>
                        <Tooltip label='Real Estate Agent Name' aria-label='REA name tooltip'>
                            <div className='flex gap-2 bg-gray-200 w-full p-1 rounded font-bold items-center'>
                                <IconUser className='text-primary' />
                                <span className='capitalize'>{first_name} {last_name}</span>
                            </div>
                        </Tooltip>
                    </div>
            </div>
            <div className='flex flex-wrap w-full gap-2 p-2 justify-center items-center'>
                <div className='flex flex-col gap-2'>
                        <Tooltip label='Facebook' aria-label='facebook tooltip'>
                            <Link className='flex bg-gray-100 p-1 rounded shadow text-lg font-bold gap-1 items-center' to={facebook_link}>
                                <IconBrandFacebook className='text-primary' />
                                <span>Facebook</span>
                            </Link>
                        </Tooltip>
                        <Tooltip label='Instagram' aria-label='instagram tooltip'>
                            <Link className='flex bg-gray-100 p-1 rounded shadow text-lg font-bold gap-1 items-center' to={insta_link}>
                                <IconBrandInstagram className='text-primary' />
                                <span>Instagram</span>
                            </Link>
                        </Tooltip>
                </div>
                <div className='flex flex-col gap-2'>
                        <Tooltip label='X.com' aria-label='x.com tooltip'>
                            <Link className='flex bg-gray-100 p-1 rounded shadow text-lg font-bold gap-1 items-center' to={x_link}>
                                <IconBrandX className='text-primary' />
                                <span>X</span>
                            </Link>
                        </Tooltip>
                        <Tooltip label='Broker Site' aria-label='broker site tooltip'>
                            <Link className='flex bg-gray-100 p-1 rounded shadow text-lg font-bold gap-1 items-center' to={broker_link}>
                                <IconHome className='text-primary' />
                                <span>Broker's Site</span>
                            </Link>
                        </Tooltip>
                </div>
                <div className='flex flex-col gap-2'>
                        <Tooltip label='LinkedIn' aria-label='LinkedIn tooltip'>
                            <Link className='flex bg-gray-100 p-1 rounded shadow text-lg font-bold gap-1 items-center' to={linkedin_link}>
                                <IconBrandLinkedin className='text-primary' />
                                <span>LinkedIn</span>
                            </Link>
                        </Tooltip>
                        <Tooltip label='Tiktok' aria-label='Tiktok tooltip'>
                            <Link className='flex bg-gray-100 p-1 rounded shadow text-lg font-bold gap-1 items-center' to={tiktok_link}>
                                <IconBrandTiktok className='text-primary' />
                                <span>Tiktok</span>
                            </Link>
                        </Tooltip>
                </div>
            </div>
        </section>
    )
}

export default SocialsSection