import React from 'react';
import { Link, useRouteError } from "react-router-dom";

function ErrorPage() {
    const error = useRouteError();
    console.error(error);
  
    return (
      <div id="error-page" className='w-full flex flex-col min-h-screen justify-center items-center gap-4'>
        <h1 className='text-xl font-extrabold'>Oops!</h1>
        <p className='font-bold text-sm'>Sorry, an unexpected error has occurred.</p>
        <p className='font-bold'>
          <i>{error.statusText || error.message}</i>
        </p>
        <Link to='/'><button className='bg-primary font-bold p-3 rounded-full shadow text-white'>Return to Home</button></Link>
      </div>
    );
}

export default ErrorPage