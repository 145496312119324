import React from 'react'

function SectionSetUp({ children, bg, styles }) {
  return (
    <section className={`w-full flex flex-col justify-center items-center ${bg} ${styles}`}>
        {children}
    </section>
  )
}

export default SectionSetUp