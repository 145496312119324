import React from 'react'
import LoginForm from '../components/loginPage/LoginForm'
import SectionSetUp from '../components/sections/SectionSetUp'

function Login() {
  return (
    <SectionSetUp styles={'min-h-screen'}>
        <LoginForm />
    </SectionSetUp>
  )
}

export default Login