import { Button, HStack, PinInput, PinInputField, Spacer, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import supabase from '../../supabase/connect.js'
import Button2 from '../buttons/Button.jsx'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
export default function PinInputComp ({ user }) {
  const inputRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [pin, setPin] = useState('')
    const [message, setMessage] = useState ({
      bool: '',
      message: ''
    })
    const checkPin = async () => {
        let { data, error } = await supabase
        .from('User')
        .select('open_house_form_pin')
        .eq('user_id', user)
        if (error) {
          console.error(error)
        }
        console.log(data[0].open_house_form_pin)
        if (data[0].open_house_form_pin === pin) {
          setMessage({
            bool: true,
            message: 'Correct Pin!'
          })
          window.location.replace('/user/dashboard')
        } else {
          setMessage({
            bool: false,
            message: 'Incorrect Pin'
          })    
        }
      }
    useEffect(() => {
      if(pin.length === 4){
        checkPin()
      }
    },[pin])
    useEffect(() => {
      if (isOpen && inputRef.current) {
        inputRef.current.focus()
      }
    },[isOpen])
    return ( 
              <div className='fixed top-10 right-10'>
                <Button2 styles='bg-primary text-white hover:bg-primary/[0.9]' onClick={onOpen}>Exit Form</Button2>
          
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Enter pin to exit form</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <HStack>
                        <Spacer />
                        <PinInput autoFocus='true'  onChange={(e) => setPin(e)}>
                          <PinInputField  />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                        <Spacer />
                      </HStack>
                    </ModalBody>
          
                    <ModalFooter>
                      <span className={`${message.bool ? 'text-greens-300' : 'text-red'}`}>{message.message}</span>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
    )
}