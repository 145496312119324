import { Input } from '@chakra-ui/react'
import { IconEdit, IconMountain } from '@tabler/icons-react'
import React from 'react'
import supabase from '../../supabase/connect'
import Button2 from '../buttons/Button'

function HeroSection({ data, user }) {
    const { personal_page } = data
    const fileInputRef = React.createRef()
    const handleFileInputClick = () => {
        // Programmatically click the hidden file input when the button is clicked
        fileInputRef.current.click();
    };
    const filterPics = async (userId) =>{
        const { data, error } = await supabase
            .storage
            .from('hero_images')
            .list(`public/${userId}`, { limit: 100 }); // Adjust path and limit as needed
        if (error) {
            console.error('Error listing images:', error.message);
            return [];
        }

        return data;
    }
    const deleteUserOldImages = async(userId, currentImageUrl) => {
        const allImages = await filterPics(userId);
        const currentImageKey = currentImageUrl.split('/').pop(); // Extract the file name from URL
        const imagesToDelete = allImages
            .filter(image => image.name !== currentImageKey) // Filter out the current image
            .map(image => `public/${userId}/hero/${image.name}`); // Prepare full paths
        if (imagesToDelete.length > 0) {
            const { data: del_data, error: del_error } = await supabase
                .storage
                .from('profilePics')
                .remove(imagesToDelete);
    
            if (del_error) {
                console.error('Error deleting images:', del_error.message);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }
    const uploadPic = async (file) => {
        let date = Date.now()
        try {
        const { data, error } = await supabase
        .storage
        .from('profilePics')
        .upload(`public/${user.id}/hero/${user.id}-${date}.jpg`, file, {
            cacheControl: '3600',
            upsert: true
        })
        if(error){
            throw new Error(error.message)
        }
        } catch (err){
            console.log(err)
        }
        const { data: public_url } = supabase
        .storage
        .from('profilePics')
        .getPublicUrl(`public/${user.id}/hero/${user.id}-${date}.jpg`)
        const { data:url, error: url_err } = await supabase
        .from('personal_page')
        .update({ hero_img: public_url.publicUrl })
        .eq('email', user.email)
                
        if(url_err){
            alert(url_err.message)
            return
        }
        await deleteUserOldImages(user.id, `public/${user.id}/${user.id}-${date}.jpg`)
        window.location.reload()
    }
    const handleFileChange = (event) => {
        // Handle file selected
        const file = event.target.files[0];
        uploadPic(file)
    };
  return (
    <header className='w-full relative h-[300px] md:h-[600px]'>
        {
            personal_page[0].hero_img ? <img src={personal_page[0].hero_img} alt='hero-img' className='object-fill w-full h-full' /> : <div className='bg-gray-300 text-white flex justify-center items-center p-8 w-full h-full '><IconMountain height={75} width={75} /></div>
        }
        <Button2 onClick={handleFileInputClick} styles='absolute bottom-4 right-4 backdrop-blur text-primary hover:bg-white/[0.9] flex items-center' color='bg-white'><IconEdit /> Change Image</Button2>
        <Input ref={fileInputRef} onChange={handleFileChange} type='file' className='hidden' />
    </header>
  )
}

export default HeroSection