import React, { useEffect, useState } from 'react'
import Auth from '../auth/Auth'
import Button2 from '../components/buttons/Button'
import ChangeUrl from '../components/customizeLandingComps/ChangeUrl'
import Description from '../components/customizeLandingComps/Description'
import HeroSection from '../components/customizeLandingComps/HeroSection'
import SocialsSection from '../components/customizeLandingComps/SocialsSection'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import supabase from '../supabase/connect'

function CustomizeLanding() {
    const [user, setUser] = useState()
    const [userData, setUserData] = useState()
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
            return
        }
        let { data: User, error } = await supabase
        .from('User')
        .select(`profilePic, first_name, last_name, personal_page (
            *
        )`)
        .eq('user_id', info.id)
        if(error){
            console.log(error.message)
            return
        }
        if (User[0].personal_page.length <= 0) {
            let { data: page, error: page_err } = await supabase
            .from('personal_page')
            .insert({ user_id: info.id, email: info.email, custom_url: `${User[0].first_name}-${User[0].last_name}-${info.id}` })
            if (page_err) {
                console.error(page_err.message)
                return
            }
            window.location.reload()
            return
        }
        setUser(info)
        setUserData(User)
    }
    useEffect(() => {
      userInfo()
    }, [])
    if (!user || !userData) {
        return <Loader />
    }
  return (
    <SectionSetUp styles='min-h-screen p-3 md:p-8'>
        <section className='shadow-lg flex flex-col items-center justify-center w-full rounded-lg bg-white overflow-hidden max-w-[1200px]'>
            <HeroSection data={userData[0]} user={user} />
            <SocialsSection data={userData[0]} user={user} />
            <div className='w-[50%] my-4 bg-primary shadow-lg rounded-lg h-[2px]'></div>
            <Description data={userData[0]} user={user} />
            <div className='w-[50%] my-4 bg-primary shadow-lg rounded-lg h-[2px]'></div>
            <ChangeUrl data={userData[0]} user={user}/>

            {/* will be here to entice agents to add to their listings */}
            <section className='gap-4 w-full bg-gray-200 p-3 py-20 w-[97%] rounded-lg mb-4 flex flex-col justify-center items-center'>
                <span className='text-2xl text-primary font-bold uppercase text-center'>Add homes to your listings</span>
                <Button2 styles='text-white text-2xl hover:bg-primary/[.9] ' color='bg-primary'>Add Listings</Button2>
            </section>
        </section>
    </SectionSetUp>
  )
}

export default CustomizeLanding