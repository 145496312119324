import React, { useEffect, useState } from 'react'
import Auth from '../auth/Auth'
import SectionTwo from '../components/dashboard/SectionTwo'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import supabase from '../supabase/connect'
import DashboardV2 from '../components/dashboard/DashboardV2'


function Dashboard() {
    const [user, setUser] = useState()
    const [userData, setUserData] = useState()
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
        }
        let { data: User, error } = await supabase
        .from('User')
        .select('profilePic, first_name, last_name, meeting(*)')
        .eq('user_id', info.id)
        if(error){
            console.log(error.message)
        }
        setUser(info)
        setUserData(User[0])
    }
    useEffect(() => {
      userInfo()
    }, [])
    if (!user) {
        return <Loader />
    }
  return (
    <SectionSetUp styles={'min-h-fit-screen'}>
        {/* <SectionOne user={user} userData={userData} /> */}
        <DashboardV2 data={userData} user={user.id} />
        {/* <SectionTwo user={user} userData={userData}/> */}
    </SectionSetUp>
  )
}

export default Dashboard