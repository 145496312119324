import React from 'react'

function Button2({ children, styles, color, onClick,type }) {
  return (
    <button type={type} onClick={onClick} className={`${styles} ${color} p-1 px-4 rounded font-bold transition duration-300 flex items-center justify-center `}>
        {children}
    </button>
  )
}

export default Button2