import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { IconMail, IconPhone } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Auth from '../auth/Auth'
import CustomAlert from '../components/alerts/CustomAlert'
import FormBTN from '../components/buttons/FormBTN'
import PinInputComp from '../components/forms/PinInputComp'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import supabase from '../supabase/connect'
import formatPhone from '../utils/formatPhone'
function OpenHouseFormPage() {
    const { form_id } = useParams()
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [user, setUser] = useState()
    const [message, setMessage] = useState({
      message: '',
      bool: ''
    })
    const [userData, setUserData] = useState()
    const handleKeyPress = (event) => {
      const newValue = event.target.value.replace(/\D/g, '');
      const num = formatPhone(newValue)
      setPhone(num)
  };
    const addContact = async(e) => {
        e.preventDefault()
        if(phone.length !== 14){
            setMessage({
              message: 'Please enter a valid phone number.',
              bool: false
            })
            setPhone('')
            return
        }
        if (email === '') {
            setMessage({
              message: 'Please enter a valid email.',
              bool: false
            })
            return
        }
        const { data, error } = await supabase
        .from('contact')
        .insert([
          { first_name: fname.trim(), phone: phone.trim(), last_name: lname.trim(), email: email.trim(), user_id: user.id, listing_id: form_id },
        ])
        if (error) {
            alert(error.message)
        }
        setMessage({
          message: 'Thanks for signing in!',
          bool: true
        })
        setEmail('')
        setFName('')
        setLName('')
        setPhone('')
    }

    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
        }
        let { data: User, error } = await supabase
        .from('User')
        .select('profilePic, first_name, last_name')
        .eq('user_id', info.id)
        if(error){
            console.log(error.message)
        }
        setUser(info)
        setUserData(User[0])
    }
    useEffect(() => {
      userInfo()
    }, [])
    if (!user) {
      return <Loader />
    }
  return (
    <SectionSetUp styles='min-h-screen'>
      <CustomAlert message={message}/>
        <PinInputComp user={user.id} />
        <form onSubmit={addContact} className='w-full max-w-[400px] bg-white rounded-lg shadow p-4 flex flex-col justify-center items-center gap-4'>
            <h1 className='text-4xl font-bold'>Please Sign In</h1>
            <div className='w-full flex gap-3'>
                <InputGroup size='lg'>
                  <Input
                    value={fname}
                    type='text'
                    placeholder='Enter First Name'
                    onChange={(e) => setFName(e.target.value)}
                  />
                </InputGroup>
                <InputGroup size='lg'>
                    <Input
                        value={lname}
                        type='text'
                        placeholder='Enter Last Name'
                        onChange={(e) => setLName(e.target.value)}
                    />
                </InputGroup>
            </div>
            <InputGroup size='lg'>
              <Input
                value={email}
                type='email'
                placeholder='Enter Email'
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputLeftElement >
                  <IconMail />
              </InputLeftElement>
            </InputGroup>
            <InputGroup size='lg'>
              <Input
                value={phone}
                type='text'
                placeholder='Enter Phone Number'
                onChange={(e) => handleKeyPress(e)}
              />
              <InputLeftElement >
                  <IconPhone />
              </InputLeftElement>
            </InputGroup>
            <FormBTN styles='bg-primary text-white text-2xl'>Sign In</FormBTN>
        </form>
        
    </SectionSetUp>
  )
}

export default OpenHouseFormPage