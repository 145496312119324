import { Input, InputGroup, InputLeftAddon, Tooltip } from '@chakra-ui/react'
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTiktok, IconBrandX, IconHome, IconHome2, IconLink, IconSpeakerphone } from '@tabler/icons-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Button2 from '../buttons/Button'
import SectionOne from '../dashboard/SectionOne'
import { saveInfo } from './helper'

function SocialsSection({ data, user }) {
    const { profilePic, first_name, last_name } = data
    const { tiktok_link, linkedin_link, broker_link, facebook_link, insta_link, page_slogan, x_link, agency_name, custom_url } = data.personal_page[0]
    const [slogan, setSlogan] = useState(page_slogan ? page_slogan : '')
    const [links, setLinks] = useState({
        fb: facebook_link ? facebook_link : '',
        insta: insta_link ? insta_link : '',
        x: x_link ? x_link : '',
        broker: broker_link ? broker_link : '',
        agency: agency_name ? agency_name : '',
        linkedin: linkedin_link ? linkedin_link : '',
        tiktok: tiktok_link ? tiktok_link : '',
    })
    const [message, setMessage] = useState('')
    const [think, setThink] = useState(true)
    const loading = async () =>  {
        setMessage('')
        setThink(false)
        const bool = await saveInfo(user.id, { linkedin_link: links.linkedin, tiktok_link: links.tiktok, broker_link: links.broker, facebook_link: links.fb, insta_link: links.insta, page_slogan: slogan, x_link: links.x, agency_name: links.agency })
        setThink(true)
        setMessage(bool)
    }
    return (
        <section className='flex flex-col w-full justify-center items-center relative'>
            <Tooltip label='Live Page'>
                <div className='absolute top-6 right-6 bg-gray-200 rounded shadow text-primary p-1 flex justify-center items-center'><Link to={`/${custom_url}`}><IconLink/></Link></div>
            </Tooltip>
            <div className='flex flex-wrap items-center p-8 w-full gap-5 justify-center'>
                {/* {
                    profilePic ? <img className=' rounded-lg shadow-lg w-[12rem] h-[12rem]' src={profilePic} alt='profile-pic'/> : <div className='bg-gray-300 w-[12rem] h-[12rem] rounded-lg flex justify-center items-center shadow-lg'><IconUser /></div>
                } */}
                    <SectionOne user={user} userData={data} />
                    <div className='w-full max-w-[600px] flex flex-col gap-4 items-center justify-center md:items-start'>
                        <span className='text-4xl font-bold'>{first_name} {last_name}</span>
                        <InputGroup>
                            <Tooltip label='Slogan' aria-label='slogan tooltip'>
                                <InputLeftAddon><IconSpeakerphone className='text-primary' /></InputLeftAddon>
                            </Tooltip>
                            <Input onChange={(e) => setSlogan(e.target.value)} className='' value={slogan} placeholder={page_slogan ? page_slogan : 'Enter your slogan here...'} type='text' />
                        </InputGroup>
                            <InputGroup>
                                <Tooltip label='Broker Name' aria-label='broker name tooltip'>
                                    <InputLeftAddon><IconHome2 className='text-primary' /></InputLeftAddon>
                                </Tooltip>
                                <Input value={links.agency} onChange={(e) => setLinks({ ...links, agency: e.target.value })} className='' placeholder={agency_name ? agency_name : 'Enter your broker name...'} type='text' />
                            </InputGroup>
                    </div>
            </div>
            <div className='flex flex-wrap w-full gap-1 p-2'>
                <div className='flex flex-col grow gap-1'>
                    <InputGroup>
                        <InputLeftAddon><Tooltip label='Facebook' aria-label='facebook tooltip'><IconBrandFacebook className='text-primary' /></Tooltip></InputLeftAddon>
                        <Input value={links.fb} onChange={(e) => setLinks({ ...links, fb: e.target.value })} type='text' placeholder='Enter facebook link' />
                    </InputGroup>
                    <InputGroup>
                        <InputLeftAddon><Tooltip label='Instagram' aria-label='instagram tooltip'><IconBrandInstagram className='text-primary' /></Tooltip></InputLeftAddon>
                        <Input value={links.insta} onChange={(e) => setLinks({ ...links, insta: e.target.value })} type='text' placeholder='Enter instagram link' />
                    </InputGroup>
                </div>
                <div className='flex flex-col grow gap-1'>
                    <InputGroup>
                        <InputLeftAddon><Tooltip label='X.com' aria-label='x.com tooltip'><IconBrandX className='text-primary' /></Tooltip></InputLeftAddon>
                        <Input value={links.x} onChange={(e) => setLinks({ ...links, x: e.target.value })} type='text' placeholder='Enter X link' />
                    </InputGroup>
                    <InputGroup>
                        <InputLeftAddon><Tooltip label='Broker Site' aria-label='broker site tooltip'><IconHome className='text-primary' /></Tooltip></InputLeftAddon>
                        <Input value={links.broker} onChange={(e) => setLinks({ ...links, broker: e.target.value })} type='text' placeholder='Enter your broker site link' />
                    </InputGroup>
                </div>
                <div className='flex flex-col grow gap-1'>
                    <InputGroup>
                        <InputLeftAddon><Tooltip label='LinkedIn' aria-label='linkedin tooltip'><IconBrandLinkedin className='text-primary' /></Tooltip></InputLeftAddon>
                        <Input value={links.linkedin} onChange={(e) => setLinks({ ...links, linkedin: e.target.value })} type='text' placeholder='Enter X link' />
                    </InputGroup>
                    <InputGroup>
                        <InputLeftAddon><Tooltip label='Tiktok' aria-label='tiktok tooltip'><IconBrandTiktok className='text-primary' /></Tooltip></InputLeftAddon>
                        <Input value={links.tiktok} onChange={(e) => setLinks({ ...links, tiktok: e.target.value })} type='text' placeholder='Enter your broker site link' />
                    </InputGroup>
                </div>
            </div>
            <div className='w-full flex items-center justify-end gap-4 p-2'>
                {
                    think ? <></> : <span className='text-primary font-bold animate-pulse'>Saving...</span>
                }
                <span className={`${message.bool ? 'text-greens-300' : 'text-red'} font-bold`}>{message.str}</span>
                <Button2 onClick={loading} styles='text-white hover:bg-primary/[0.9] text-2xl ' color='bg-primary'>Save</Button2>
            </div>
        </section>
    )
}

export default SocialsSection