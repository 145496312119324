import { Button, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { IconKey, IconMail } from '@tabler/icons-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import supabase from '../../supabase/connect'
import FormBTN from '../buttons/FormBTN'
function LoginForm() {
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const login = async (e) => {
        e.preventDefault()
        let { data, error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password
          })
          if (error){
            setErrorMessage(error.message)
            return
          }
        window.location.replace(`/user/dashboard`)
    }
  return (
    <form onSubmit={login} className='bg-white w-full max-w-[400px] mx-auto rounded-lg p-6 shadow shadow-dark-600 flex flex-col justify-center items-center gap-3 relative'>
        <h1 className='text-secondary font-bold text-3xl'>Sign In</h1>
        <InputGroup size='md'>
          <Input
            type='email'
            placeholder='Enter Email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputLeftElement >
              <IconMail />
          </InputLeftElement>
        </InputGroup>
        {/* <TextInput onChange={(e) => setEmail(e.target.value)} type='email' placeholder='Enter Email' label='Email' labelCol='' /> */}
        <InputGroup size='md'>
          <Input
            pr='4.5rem'
            type={show ? 'text' : 'password'}
            placeholder='Enter password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputLeftElement>
            <IconKey />
          </InputLeftElement>
          <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='sm' onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
        {/* <TextInput onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Enter Password' label='Password' labelCol='' /> */}
        {
            errorMessage ? <span className='text-red font-bold w-full text-right'>{errorMessage}</span> : <></>
        }
        <FormBTN styles='bg-primary text-white hover:bg-primary/[.9] text-xl'>Sign In</FormBTN>
        <Link to='/createaccount' className='absolute bottom-[-25px] right-0 font-bold text-sm text-primary'>Don't have an account yet? click here.</Link>
    </form>
  )
}

export default LoginForm