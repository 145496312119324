import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Auth from '../auth/Auth'
import LeftContactSide from '../components/contactPageComps/LeftContactSide'
import RightContactSide from '../components/contactPageComps/RightContactSide'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import supabase from '../supabase/connect'
function ContactPage() {
    const { contact_id } = useParams()
    const [user, setUser] = useState()
    const [userData, setUserData] = useState()
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
        }
        let { data: User, error } = await supabase
        .from('contact')
        .select('*')
        .eq('id', contact_id)
        if(error){
            console.log(error.message)
        }
        let { data: listing_address, error: err2 } = await supabase
        .from('listing')
        .select('address')
        .eq('id', User[0].listing_id)
        if(err2){
            console.log(err2.message)
        }
        let obj = {
            user: User[0],
            address: listing_address[0].address
        }
        setUser(info)
        setUserData(obj)
    }
    useEffect(() => {
        userInfo()
    }, [])
    if (!user || !userData) {
        return <Loader />
    }
  return (
    <SectionSetUp styles='min-h-fit-screen'>

        <section className='w-full flex justify-center items-center bg-white border-b border-primary  '>
            <LeftContactSide userData={userData} user={user.id} />
        </section>
        <section className='w-full  '>
            <RightContactSide userData={userData.user} user={user.id} />
        </section>
        
    </SectionSetUp>
  )
}

export default ContactPage