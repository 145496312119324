import { useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/sass/styles.scss';
import { addHours } from 'date-fns';
import supabase from '../../supabase/connect';

const localizer = momentLocalizer(moment)

export default function DashCalendar ({ events }) {
    const [dates, setDates] = useState('')
    const formatEvents = async () => {
        let { data: meeting, error } = await supabase
        .from('meeting')
        .select('*')
        if (error){
            console.error(error)
        }
        const arr = []
        for (let i=0; i < meeting.length; i++){
            let day_str = meeting[i].date
            let stamp = Date.parse(day_str)
            let day = new Date(stamp)
            arr.push({
                title: meeting[i].title,
                start: new Date(meeting[i].date),
                end: addHours(day, 1),
                allDay: false
            })
        }
        setDates(arr)
    }

    useEffect(() => {
        formatEvents()
    },[])

    return (
        <div className='w-full bg-white p-2 rounded-lg shadow'>
        <Calendar
          defaultView='agenda'
          localizer={localizer}
          events={dates ? dates : []}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
    )
}