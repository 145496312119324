import React from 'react'
import ListingList from '../listsComp/ListingList'

function Listings({ listing_data }) {
  return (
    <section className='gap-4 w-full bg-gray-200 p-3 py-20 w-[97%] rounded-lg mb-4 flex flex-col justify-center items-center'>
        {
            listing_data.length > 0 ? <ListingList userData={listing_data}/> : (
                <>
                    <span className='text-2xl text-primary font-bold uppercase text-center'>No listings to show</span>
                </>
            )
        }

    </section>
  )
}

export default Listings