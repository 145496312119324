// import {
//   ApolloClient,
//   ApolloProvider,
//   InMemoryCache,
//   createHttpLink
// } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './errorPages/ErrorPage';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ContactBook from './routes/ContactBook';
import ContactPage from './routes/ContactPage';
import CustomizeLanding from './routes/CustomizeLanding';
import Dashboard from './routes/Dashboard';
import Landing from './routes/Landing';
import ListingsPage from './routes/ListingsPage';
import Login from './routes/Login';
import NewListingForm from './routes/NewListingForm';
import NewUser from './routes/NewUser';
import OpenHouseFormPage from './routes/OpenHouseFormPage';
import PersonalLanding from './routes/PersonalLanding';
import Root from './routes/Root';
import SingleListingPage from './routes/SingleListingPage';
import ListingCheckForContactForm from './routes/ListingCheckForContactForm';
// const graphqlUri = process.env.REACT_APP_URL
// const httpLink = createHttpLink({
//   uri: graphqlUri, 
// })
// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('9janK8iwPc')
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   }
// })
// const client = new ApolloClient({
//   // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache(),
// })
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Landing />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/createaccount',
        element: <NewUser />
      },
      {
        path: '/user/dashboard',
        element: <Dashboard />
      },
      {
        path: '/openhouseform/listing/:form_id',
        element: <OpenHouseFormPage />
      },
      {
        path: '/contactbook',
        element: <ContactBook />
      },
      {
        path: '/contact/:contact_id',
        element: <ContactPage />
      },
      {
        path: '/personalLanding',
        element: <CustomizeLanding />
      },
      {
        path: '/:custom_url',
        element: <PersonalLanding />
      },
      {
        path: '/newListing',
        element: <NewListingForm />
      },
      {
        path: '/listings',
        element: <ListingsPage />
      },
      {
        path: '/listings/:listing_id',
        element: <SingleListingPage />
      },
      {
        path: '/openhouseform/listing',
        element: <ListingCheckForContactForm />
      }
    ]
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ApolloProvider client={client}> */}
      <RouterProvider router={router} />
    {/* </ApolloProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
