
import React from 'react'
import Button2 from '../buttons/Button'
import H1 from '../headers/H1'
import SectionSetUp from '../sections/SectionSetUp'
const paymentInfo = [
  {
    name: 'basic',
    featured_plan: false,
    price:'free',
    freq: '',
    details: ['Can have up to 50 contacts', 'Can create personal landing page', 'Can have up to 4 listings', 'No credit card required!']
  },
  {
    name: 'pro',
    featured_plan: true,
    price:'15',
    freq: 'month',
    details: ['Can have unlimited contacts', 'Can create personal landing page', 'Can have unlimited listings']
  },
  {
    name: 'premium',
    featured_plan: false,
    price:'7',
    freq: 'month',
    details: ['Can have unlimited contacts', 'Can create personal landing page', 'Can have up to 15 listings']
  }
]
function PricingSection() {

  return (
    <SectionSetUp styles='py-20'>
      {/* Payments Section */}
        <H1 className="text-secondary">Pricing Plans</H1>
        <p className="text-primary mb-6 font-bold text-lg">Choose a plan that works for you.</p>
        <section className='flex flex-col md:flex-row gap-3 w-full justify-center items-center '>
        {
          paymentInfo.map((each, index) => {
            return (
              <div key={index} className={` ${each.featured_plan ? '': ''} flex flex-col justify-start items-center grow max-w-[325px] rounded-lg bg-white shadow overflow-hidden h-[400px]`}>
                <div className={`w-full flex justify-center items-center p-2 text-white font-bold text-lg uppercase ${each.featured_plan ? 'bg-primary': 'bg-gray-400'}`}>
                  <span>{each.name}</span>
                </div>
                <div className='w-full flex justify-center items-start p-2 text-secondary font-bold text-lg uppercase mt-3'>
                  <span className='text-4xl'>{each.price === 'free' ? 'free' : `$${each.price}`}</span>
                  <span className='text-sm'>{each.price === 'free' ? '' : `/${each.freq}`}</span>
                </div>
                <div className='bg-primary w-full h-[2px] mt-3'></div>
                <div className='mt-3 flex flex-col justify-start items-start w-full px-4 gap-3 h-[200px]'>
                  {
                    each.details.map((item, index) => {
                        return (
                          <span key={index} className='font-bold '>- {item}</span>
                        )
                    })
                  }
                </div>
                <Button2 styles='my-3 bg-primary text-white font-bold uppercase'>Choose Plan</Button2>
              </div>
            )
          })
        }

        </section>
    </SectionSetUp>
  )
}

export default PricingSection