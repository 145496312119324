import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import NewContactForm from '../forms/NewContactForm'

function Drawer1({ user, reGet }) {
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
  return (
    <>
        <Button ref={btnRef} colorScheme='twitter' onClick={onOpen}>
        + Contact
        </Button>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Add a New Contact</DrawerHeader>
                <DrawerBody>
                    <NewContactForm user_id={user.id} reGet={reGet}  />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
  </>
  )
}

export default Drawer1