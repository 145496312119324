import React from 'react'
import NewUserForm from '../components/newUserPage/NewUserForm'
import SectionSetUp from '../components/sections/SectionSetUp'

function NewUser() {
  return (
    <SectionSetUp styles={'min-h-screen'}>
        <NewUserForm />
    </SectionSetUp>
  )
}

export default NewUser