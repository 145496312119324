import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { IconMail, IconPhone, IconUser } from '@tabler/icons-react'
import React, { useState } from 'react'
import supabase from '../../supabase/connect'
import FormBTN from '../buttons/FormBTN'

function NewContactForm({ user_id, reGet }) {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [fname, setFName] = useState('')
  const [lname, setLName] = useState('')
  const [user, setUser] = useState()
  const [message, setMessage] = useState({
    message: '',
    bool: ''
  })
  const [userData, setUserData] = useState()
  const addContact = async(e) => {
    e.preventDefault()
    if(phone.length !== 10){
        setMessage({ message:'Please enter a valid phone number.', bool: false })
        setPhone('')
        return
    }
    if (email === '') {
        setMessage({ message:'Please enter a valid email.', bool: false })
        return
    }
    if (fname === '' || lname === '') {
      setMessage({ message:'Please enter a first name and last name.', bool: false })
      return
  }
    const { data, error } = await supabase
    .from('contact')
    .insert([
      { first_name: fname.toLowerCase().trim(), phone: phone.trim(), last_name: lname.toLowerCase().trim(), email: email.trim(), user_id: user_id },
    ])
    if (error) {
        alert(error.message)
    }
    setMessage({ message:'Contact Added!', bool: true })
    setEmail('')
    setFName('')
    setLName('')
    setPhone('')
    reGet()
}
  return (
<form onSubmit={addContact} className='w-full flex flex-col justify-center items-center gap-4'>
            <div className='bg-gray-400 rounded-full flex justify-center items-center text-white p-2'>
              <IconUser height={75} width={75} />
            </div>
            <div className='w-full flex gap-3'>
                <InputGroup size='lg'>
                  <Input
                    value={fname}
                    type='text'
                    placeholder='Enter First Name'
                    onChange={(e) => setFName(e.target.value)}
                  />
                </InputGroup>
                <InputGroup size='lg'>
                    <Input
                        value={lname}
                        type='text'
                        placeholder='Enter Last Name'
                        onChange={(e) => setLName(e.target.value)}
                    />
                </InputGroup>
            </div>
            <InputGroup size='lg'>
              <Input
                value={email}
                type='email'
                placeholder='Enter Email'
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputLeftElement >
                  <IconMail />
              </InputLeftElement>
            </InputGroup>
            <InputGroup size='lg'>
              <Input
                value={phone}
                type='text'
                placeholder='Enter Phone Number'
                onChange={(e) => setPhone(e.target.value)}
              />
              <InputLeftElement >
                  <IconPhone />
              </InputLeftElement>
            </InputGroup>
            <FormBTN styles='bg-primary text-white text-2xl'>Add Contact</FormBTN>
            <span className={`${message.bool ? 'text-greens-400' : 'text-red'} my-3 text-lg font-bold text-center`}>{message.message}</span>
        </form>
  )
}

export default NewContactForm