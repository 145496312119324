import { Card, CardBody, Heading, Stack, Text } from '@chakra-ui/react';
import { IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ListingList({ userData }) {
    const [url, setUrl] = useState(window.location.pathname)
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2 // Specifies the number of decimal places
      });
  return (
    <div className='auto-rows-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2'>
    {
        userData.map(each => {
            const formattedAmount = formatter.format(each.price);
            return (
                    <Link reloadDocument key={each.id} className='relative' to={window.location.pathname === '/openhouseform/listing' ? `/openhouseform/listing/${each.id}` :`/listings/${each.id}`}>
                        <div className={`${url !== '/openhouseform/listing' ? 'hidden': 'absolute'} bg-greens-300 z-50 bottom-4 right-4 text-white rounded-full shadow`}><IconPlus /> </div>
                        <Card key={each.id}>
                            <CardBody>
                                <img className='w-[300px] h-[300px] object-cover rounded-lg shadow' src={each.images[0]} alt='home img' />
                                <Stack mt='6' spacing='3'>
                                <Heading size='md'>{formattedAmount}</Heading>
                                <Text fontSize='lg'>
                                    {each.address}
                                </Text>
                                <Text >
                                    <span className=" "><span className='text-[.75em] font-bold uppercase'>{each.agency_name}</span>, <span className='text-[0.7em]'>{each.realtor}</span></span>
                                </Text>
                                </Stack>
                            </CardBody>
                        </Card>
                    </Link>
            )
        })
    }
    </div>
  )
}

export default ListingList