import React from 'react';
const parser = new DOMParser();


const HtmlContentDisplay = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

function Description({ data }) {
    const { agent_description } = data.data
    const doc = parser.parseFromString(agent_description, 'text/html')
    const bodyContent = doc.body.innerHTML;
    
  return (
    <section className='w-full rounded-lg flex flex-col justify-center items-center p-2'>
        <section className='bg-gray-100 p-3 w-full text-center text-lg shadow'>
          <HtmlContentDisplay htmlContent={bodyContent} />
        </section>
    </section>
  )
}

export default Description