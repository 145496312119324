import supabase from "../supabase/connect"
class AuthServices {

    check = async () => {
        const { data: { user } } = await supabase.auth.getUser()
        
        if (user){
            return true
        } else {
            return false
        }
    }

    loggedIn = async() => {
        const logged = await this.check()
        if (logged) {
            return true
        } else {
            return false
        }
    }

    userInfo = async () => {
        try {
        const { data: { user }, error } = await supabase.auth.getUser()
        if (error) {
            console.error(error)
            return
        }
        return user
        } catch (err) {
            console.error(err)
            return
        }

    }


}

const Auth = new AuthServices()

export default Auth