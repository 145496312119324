import { Alert, AlertIcon, AlertTitle, Avatar, Card, CardBody, CardFooter, Center, Divider, Heading, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button2 from "../buttons/Button";

export default function ContactCard ({ info }) {
    const [alert, setAlert] = useState('hidden')

    const copyClipboard = async (str) => {
        try {
            await navigator.clipboard.writeText(str)
            setAlert('fixed')
            setTimeout(() => {
                setAlert('hidden');
            }, 2000)
        } catch (err) {
            console.error('Failed to copy text: ', err)
        }
    }
    return (
<Card  minW='sm'>
        <div className={`${alert} top-16 right-[1rem] rounded-lg shadow overflow-hidden animate-pulse`}>
            <Alert status='success' >
                <AlertIcon />
                <AlertTitle>Copied!</AlertTitle>
            </Alert>
        </div>
  <CardBody>
    <Stack mt='1'>
        <Stack spacing='3'>
            <Center>
                <Heading size='md'><Avatar size='md' name={`${info.first_name} ${info.last_name}`} /></Heading>
            </Center>
            <Center>
                <Stack spacing='3'>
                    <Text>
                      <span className="capitalize font-bold bg-gray-100 p-1 rounded-lg border px-2">{info.first_name} </span>
                    </Text>
                    <Text>
                      <span className="capitalize font-bold bg-gray-100 p-1 rounded-lg border px-2">{info.last_name} </span>
                    </Text>
                </Stack>
            </Center>
        </Stack>

      <Text  onClick={() => copyClipboard(info.email)} color='blue.600' fontSize='md'>
        Email: <span className="cursor-pointer underline"> {info.email}</span>
      </Text>
      <Text color='blue.600' fontSize='md'>
        Phone #: {info.phone}
      </Text>
    </Stack>
  </CardBody>
  <Divider />
  <CardFooter>
    <Link to={`/contact/${info.id}`}><Button2 styles='text-white my-3' color='bg-primary'>View</Button2></Link>
  </CardFooter>
</Card>
    )
}