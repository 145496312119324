import { Input, InputGroup, InputLeftAddon, Tooltip } from '@chakra-ui/react'
import { IconLink } from '@tabler/icons-react'
import React, { useState } from 'react'
import Button2 from '../buttons/Button'
import { saveInfo } from './helper'

function ChangeUrl({ data, user }) {
    const { custom_url } = data.personal_page[0]
    const [url, setURL] = useState(custom_url ? custom_url : '')
    const [message, setMessage] = useState('')
    const [think, setThink] = useState(true)
    const loading = async () =>  {
        setMessage('')
        setThink(false)
        const bool = await saveInfo(user.id, { custom_url: url })
        setThink(true)
        setMessage(bool)
    }
  return (
    <section className='w-full flex flex-col justify-center items-center p-2 gap-3 mb-4'>
        <span className='text-2xl font-bold'>Manage your custom url</span>
        <InputGroup>
            <Tooltip label='Custom URL' aria-label='url tooltip'>
                <InputLeftAddon><IconLink className='text-primary' /></InputLeftAddon>
            </Tooltip>
            <Input onChange={(e) => setURL(e.target.value)} className='' value={url} placeholder={custom_url ? custom_url : 'Enter your url here...'} type='text' />
        </InputGroup>
        <div className='w-full flex items-center justify-end gap-4 p-2'>
                {
                    think ? <></> : <span className='text-primary font-bold animate-pulse'>Saving...</span>
                }
                <span className={`${message.bool ? 'text-greens-300' : 'text-red'} font-bold`}>{message.str}</span>
                <Button2 onClick={loading} styles='text-white hover:bg-primary/[0.9] text-2xl ' color='bg-primary'>Change</Button2>
            </div>
    </section>
  )
}

export default ChangeUrl