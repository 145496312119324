import { IconAppWindow, IconBusinessplan, IconHome, IconHome2, IconHomeEdit, IconSettings, IconUser } from "@tabler/icons-react"

const routes = [
    {
        icon: <IconHome />,
        route: '/openhouseform/listing',
        tooltip: 'Open House Form'
    },
    {
        icon: <IconUser/>,
        route: '/ContactBook',
        tooltip: 'Contacts'
    },
    {
        icon: <IconAppWindow/>,
        route: '/personalLanding',
        tooltip: 'Landing Page'
    },
    {
        icon: <IconHomeEdit/>,
        route: '/newListing',
        tooltip: 'Listing Form'
    },
    {
        icon: <IconHome2/>,
        route: '/listings',
        tooltip: 'Listings'
    },
    {
        icon: <IconBusinessplan/>,
        route: '/subscription',
        tooltip: 'Manage Subscription'
    },
    {
        icon: <IconSettings/>,
        route: '/settings',
        tooltip: 'Settings'
    }
]

export default routes