import supabase from "../../supabase/connect"

export const NavLogout = () => {
    const logout = async () => {
        let { error } = await supabase.auth.signOut()
        window.location.replace('/')
    }
    return (
        <div ><span className="text-red font-bold cursor-pointer"  onClick={logout}>Sign Out</span></div>
    )
}