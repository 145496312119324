import { Input, InputGroup, InputLeftElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Tooltip } from '@chakra-ui/react'
import { IconBathFilled, IconBedFilled, IconHome, IconMoneybag, IconMountain, IconNumber, IconRuler, IconZoomMoneyFilled } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import Auth from '../auth/Auth'
import FormBTN from '../components/buttons/FormBTN'
import ListingImgDrop from '../components/fileDrops/ListingImgDrop'
import QuillText from '../components/richTextEditors/QuillText'
import SectionSetUp from '../components/sections/SectionSetUp'
import '../css/textEditorCSS.css'
import supabase from '../supabase/connect'
const desc_html = `
    <section>
        <span class='title' ><b>Description</b></span>
        <p>Write description here....</p>
        <span><b>Appliances</b></span>
        <p>Add Appliances</p>

    </section>
`
function NewListingForm() {
    const [images, setImages] = useState([])
    const [address, setAddress] = useState('')
    const [price, setPrice] = useState('')
    const [type, setType] = useState('')
    const [size, setSize] = useState('')
    const [age, setAge] = useState('')
    const [beds, setBeds] = useState('')
    const [baths, setBaths] = useState('')
    const [HOA, setHOA] = useState('')
    const [desc, setDesc] = useState(desc_html)
    const [message, setMessage] = useState('')
    const [user, setUser] = useState()
    const [userData, setUserData] = useState('')
    const [acres, setAcres] = useState('')
    const [url, setUrl] = useState([])
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
        }
        let { data: User, error } = await supabase
        .from('User')
        .select('first_name, last_name, personal_page(agency_name)')
        .eq('user_id', info.id)
        if(error){
            console.log(error.message)
        }
        setUser(info)
        setUserData(User[0])
    }
    const newListing = async (e) => {
        e.preventDefault()
        let arr_url = []
        const if_arr = [address, price, type, size, age,beds,baths,HOA,desc,acres]
        for (let i = 0; i < if_arr.length; i++){
            if (if_arr[i] === ''){
                setMessage({
                    message: 'please fill out each field.',
                    bool: false
                })
                return
            }
        }
        const obj = { user_id: user.id, address: address, price: price, sq_feet: size, description: desc, type: type, acres: acres, year_built: age, bedrooms: beds, baths: baths, HOA: HOA, additional_features: [], realtor: `${userData.first_name} ${userData.last_name}`, agency_name: userData.personal_page[0].agency_name }
        const { data: listing_data, error } = await supabase
        .from('listing')
        .insert([
            obj
        ]).select()
        if (error) {
            setMessage({
                message: 'Unable to create listing. Try again.',
                bool: false
            })
            return
        }
        if (images.length > 0) {
            const uploadPromises = images.map((image, index) => {
                const filePath = `public/listings/${listing_data[0].id}/${listing_data[0].address}${index}.jpeg`;
                return supabase.storage.from('profilePics').upload(filePath, image, {
                    cacheControl: '3600',
                    upsert: true
                });
            });
            try {
                const results = await Promise.all(uploadPromises);
                
                results.forEach((result, index) => {
                    if (result.error) {
                        console.error(result.error);
                        return; // Or handle this more gracefully
                    }
                    const bucket = supabase.storage.from('profilePics').getPublicUrl(`public/listings/${listing_data[0].id}/${listing_data[0].address}${index}.jpeg`);
                    arr_url.push(bucket.data.publicUrl)
                });
            } catch (error) {
                console.error('Failed to upload one or more images:', error);
            }
            const { data: updated, error } = await supabase
            .from('listing')
            .update({ images: arr_url })
            .eq('id', listing_data[0].id)
            .select()
            if (error) {
                console.error(error)
                setMessage({
                    message: 'Unable to upload images. Try again.',
                    bool: false
                })
                return
            }   
        }
        const arr = [setUrl, setImages, setAddress, setAcres, setPrice, setType, setAge, setBeds, setBaths, setHOA, setDesc, setMessage, setSize]
        for (let i = 0; i < arr.length; i++) {
            arr[i]('')
        }
        arr_url =[]
        setMessage({
            message: 'Listing Created!',
            bool: true
        })
    }
    useEffect(() => {
        userInfo()
      }, [])
  return (
    <SectionSetUp styles='min-h-screen'>
        <form onSubmit={newListing} className='bg-white w-full max-w-[600px] mx-auto rounded-lg p-6 shadow shadow-dark-600 flex flex-col justify-center items-center gap-4 relative'>
            <h1 className='text-secondary font-bold text-3xl'>Listing</h1>
            {/* address input */}
            <Tooltip label='Address' aria-label='Address tooltip'>
                <InputGroup size='md'>
                    <Input
                        value={address}
                        type='text'
                        placeholder='Enter address'
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <InputLeftElement >
                        <IconHome />
                    </InputLeftElement>
                </InputGroup>
            </Tooltip>
            {/* Price, type, and squarefeet */}
            <div className='w-full flex gap-1 items-center flex-col md:flex-row'>
                {/* price */}
                <Tooltip label='Asking Price' aria-label='price tooltip'>
                    <div className='flex relative  flex-col w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                            <IconMoneybag className='' />
                        </div>
                        <NumberInput value={price} defaultValue={0} min={0} max={1000000000} onChange={(e) => setPrice(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
                
                {/* type change to select */}
                <Tooltip label='Property Type' aria-label='property tooltip'>
                    <Select onChange={(e) =>  {
                        setType(e.target.value)
                    }} value={type} placeholder='Select Type'>
                        <option value='Single Family Home'>Single Family Home</option>
                        <option value='Multifamily'>Multifamily</option>
                        <option value='Townhome'>Townhome</option>
                        <option value='Condo'>Condo</option>
                    </Select>
                </Tooltip>
                {/* squarefeet */}
                <Tooltip label='Square Feet' aria-label='sq feet tooltip'>
                    <div className='flex relative  flex-col w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                            <IconRuler className='' />
                        </div>
                        <NumberInput value={size} defaultValue={0} min={0} max={100000000} onChange={(e) => setSize(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
            </div>
            <div className='w-full flex gap-4 md:gap-1 items-center flex-col md:flex-row justify-center'>
                {/* year built */}
                <Tooltip label='Year Built' aria-label='baths tooltip'>
                    <div className='flex flex-col relative w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                           <IconNumber className='' />
                        </div>
                        <NumberInput value={age} defaultValue={0} min={0} max={10000000} onChange={(e) => setAge(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
                {/* bedrooms */}
                <Tooltip label='# Bedrooms' aria-label='baths tooltip'>
                    <div className='flex flex-col relative w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                           <IconBedFilled className='' />
                        </div>
                        <NumberInput value={beds} defaultValue={0} min={0} max={30} onChange={(e) => setBeds(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
                {/* baths */}
                <Tooltip label='# Baths' aria-label='baths tooltip'>
                    <div className='flex relative  flex-col w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                           <IconBathFilled className='' />
                        </div>
                        <NumberInput value={baths} defaultValue={0} min={0} max={30} onChange={(e) => setBaths(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
            </div>
            <div className='w-full flex gap-4 md:gap-1 items-center flex-col md:flex-row justify-center'>
                <Tooltip label='# acres' aria-label='hoa tooltip'>
                    <div className='flex relative  flex-col w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                            <IconMountain className='' />
                        </div>
                        <NumberInput value={acres} defaultValue={0} min={0} max={10000000} onChange={(e) => setAcres(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
                <Tooltip label='HOA Fee' aria-label='hoa tooltip'>
                    <div className='flex relative  flex-col w-full'>
                        <div className='absolute top-[-12px] left-0 z-[100]'>
                            <IconZoomMoneyFilled className='' />
                        </div>
                        <NumberInput value={HOA} defaultValue={0} min={0} max={1000000} onChange={(e) => setHOA(e)}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>
                </Tooltip>
            </div>

            <Tooltip label='Description' aria-label='year tooltip'>
                <QuillText val={desc} setVal={setDesc} />
            </Tooltip>
            <ListingImgDrop images={images} setImages={setImages} />
            <span className={`w-full text-right ${message.bool ? 'text-greens-300' : 'text-red'}`}>{message.message}</span>
            {/* form btn */}
            <FormBTN styles='bg-primary text-white text-lg'>Add</FormBTN>
        </form>
    </SectionSetUp>
  )
}

export default NewListingForm