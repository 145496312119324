import { Input, InputGroup, InputLeftAddon, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { IconCalendar, IconWriting } from '@tabler/icons-react'
import React, { useState } from 'react'
import supabase from '../../supabase/connect'
import Button2 from '../buttons/Button'
import Calendar from '../calendars/Calendar'
import DashCalendar from '../calendars/DashCalendar'
import EventForm from '../forms/EventForm'
function RightContactSide({ userData, user }) {

  return (
        <Tabs isFitted size='lg' variant='enclosed'>
          <TabList className=' font-extrabold'>
            <Tab>Meetings</Tab>
            <Tab>Email</Tab>
            <Tab>IDK Yet</Tab>
          </TabList>
          <TabPanels>
            <TabPanel >
                {/* <div style={{ width: '300px', height: '500px' }}>
                    <MeetingCalendar />
                </div> */}
                <div className=''>
                    <DashCalendar events={userData.meeting} />
                    <EventForm user={user}/>
                </div>
                
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
  )
}

export default RightContactSide