import { IconAppWindow, IconHomeDollar } from '@tabler/icons-react'
import React from 'react'
import { Card } from '../cards/ItemCards'
import H1 from '../headers/H1'
import SectionSetUp from '../sections/SectionSetUp'
const connectItems = [
    {
        id: 0,
        icon: <IconHomeDollar height={50} width={50} />,
        phrase: 'At open houses',
        desc: 'leave the form open for potential clients to leave their information.'
    },
    {
        id: 1,
        icon: <IconAppWindow height={50} width={50} />,
        phrase: 'With your personal page',
        desc: 'create and edit your personal contact page.'
    },
    // {
    //     id: 2,
    //     icon: <IconMessages height={50} width={50} />,
    //     phrase: "Through multiple social media channels",
    //     desc: 'Create social media posts and share them to all of your social media accounts.'
    // },
]
function ConnectSection() {
  return (
    <SectionSetUp bg='bg-gray-200' styles='py-20'>
      {/* Call to Action Section */}
        <H1 styles='text-primary'>
            CONNECT
        </H1>
        <h2 className='text-gray-400 font-bold text-center'>
            Use Agent Buddy's to connect with leads:
        </h2>
        <div className='w-[10%] my-6 h-[3px] bg-primary rounded-full shadow'></div>
        <section className='flex flex-col md:flex-row gap-2 justify-center items-stretch  my-4'>
        {
            connectItems.map(each => {
                return (
                    <Card key={each.id}>
                        <Card.Item>
                            <div className='text-primary'>
                                {
                                    each.icon
                                }
                            </div>
                        </Card.Item>
                        <Card.Item>
                            <span className='text-gray-400 font-bold text-lg'>
                                {
                                    each.phrase
                                }
                            </span>
                        </Card.Item>
                        <Card.Item>
                            <span className='text-gray-300 font-bold text-sm'>
                                {
                                    each.desc
                                }
                            </span>
                        </Card.Item>
                    </Card>
                )
            })
        }
        </section>
    </SectionSetUp>
  )
}

export default ConnectSection