import React, { useState } from 'react'
import Button2 from '../buttons/Button'
import QuillText from '../richTextEditors/QuillText'
import { saveInfo } from './helper'

function Description({ data, user }) {
    const { id } = user
    const { agent_description } = data.personal_page[0]
    const [desc, setDesc] = useState(agent_description ? agent_description : '')
    const [message, setMessage] = useState('')
    const [think, setThink] = useState(true)
    const loading = async () =>  {
        setMessage('')
        setThink(false)
        const bool = await saveInfo(id, { agent_description: desc })
        setThink(true)
        setMessage(bool)
    }
    
  return (
    <section className='w-full rounded-lg flex flex-col justify-center items-center p-2'>
        <span className='text-4xl font-bold'>Describe yourself</span>
        <QuillText val={desc} setVal={setDesc} />
        <div className='w-full flex items-center justify-end gap-4'>
            {
                think ? <></> : <span className='text-primary font-bold animate-pulse'>Saving...</span>
            }
            <span className={`${message.bool ? 'text-greens-300' : 'text-red'} font-bold`}>{message.str}</span>
            <Button2 onClick={loading} styles='text-white hover:bg-primary/[0.9] text-2xl ' color='bg-primary'>Save</Button2>
        </div>
    </section>
  )
}

export default Description