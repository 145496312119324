import { useEffect, useState } from "react"
import Auth from "../auth/Auth"
import ListingList from '../components/listsComp/ListingList'
import Loader from "../components/loaders/Loader"
import SectionSetUp from "../components/sections/SectionSetUp"
import supabase from "../supabase/connect"
const ListingsPage = () => {
    const [user, setUser] = useState()
    const [userData, setUserData] = useState()
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2 // Specifies the number of decimal places
      });
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (!info){
            window.location.replace('/')
        }
        let { data: User, error } = await supabase
        .from('User')
        .select('listing(*), first_name, last_name')
        .eq('user_id', info.id)
        if(error){
            console.log(error.message)
        }
        setUser(info)
        setUserData(User[0])
    }
    console.log(userData)
    useEffect(() => {
      userInfo()
    }, [])
    if (!user || !userData) {
        return <Loader />
    }
    return (
        <SectionSetUp styles={`min-h-fit-screen p-1`}>
            <ListingList userData={userData.listing} />
            
        </SectionSetUp>
    )
}

export default ListingsPage