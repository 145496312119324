import React from 'react'

function FormBTN({ children, styles }) {
  return (
    <button className={`${styles} py-1 px-4 flex justify-center items-center font-bold rounded-lg shadow`}>
        {children}
    </button>
  )
}

export default FormBTN