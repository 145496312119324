import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Description from '../components/PersonalLandingComps/Description'
import HeroSection from '../components/PersonalLandingComps/HeroSection'
import Listings from '../components/PersonalLandingComps/Listings'
import SocialsSection from '../components/PersonalLandingComps/SocialsSection'
import Loader from '../components/loaders/Loader'
import SectionSetUp from '../components/sections/SectionSetUp'
import supabase from '../supabase/connect'
function PersonalLanding() {
    const { custom_url } = useParams()
    const [userData, setUserData] = useState('')
    const [user, setUser] = useState('')
    const getInfo = async () => {
        let { data: personal_page, error } = await supabase
        .from('personal_page')
        .select('*')
        .eq('custom_url', custom_url)
        if (error) {
            console.log(error.message)
            return
        }
        let { data: User, error: user_err } = await supabase
        .from('User')
        .select('first_name, last_name, profilePic, listing(*)')
        .eq('user_id', personal_page[0].user_id)
        if (user_err) {
            console.log(user_err.message)
            return
        }
        setUserData({
            first_name: User[0].first_name,
            last_name: User[0].last_name,
            profile_pic: User[0].profilePic,
            data: personal_page[0],
            listings: User[0].listing
        })
    }

    useEffect(() => {
        getInfo()
    },[])
    if (!userData) {
        return <Loader />
    }
  return (
    <SectionSetUp styles='min-h-screen mb-1' bg=''>
        <section className='shadow-lg flex flex-col items-center justify-center w-full rounded-lg bg-white overflow-hidden max-w-[1200px]'>
            <HeroSection data={userData} />
            <SocialsSection data={userData} />
            <div className='w-[50%] my-4 bg-primary shadow-lg rounded-lg h-[2px]'></div>
            <Description data={userData} />
            <div className='w-[50%] my-4 bg-primary shadow-lg rounded-lg h-[2px]'></div>

            {/* will be where the listings go */}
            <Listings listing_data={userData.listings} />

        </section>
    </SectionSetUp>
  )
}

export default PersonalLanding