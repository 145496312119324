import { Center, Flex, HStack, Spacer, Stack, Tooltip } from "@chakra-ui/react";
import DashCalendar from "../calendars/DashCalendar";
import EventForm from "../forms/EventForm";
import { Link } from 'react-router-dom'
import routes from "./navRoutes";
export default function DashboardV2 ({ data, user }) {

    return (
        <Stack w='98%' maxW={'1200px'} spacing='3' marginBottom={'3'} >
            <section className="flex justify-between items-center w-full bg-primary rounded-lg shadow p-2 px-4">
                {
                    routes.map((each,index) => {
                        return (
                                <Link reloadDocument key={index} to={each.route}>
                                    <Tooltip label={each.tooltip}>
                                        <div className="text-white">
                                            {
                                                each.icon
                                            }
                                        </div>
                                    </Tooltip>
                                </Link>
                        )
                    })
                }
            </section>
            <DashCalendar events={data.meeting} />
            <Stack w='100%'>
                <EventForm user={user} />
            </Stack>

        </Stack>
    )
}