import React from 'react'
import Button2 from '../buttons/Button'
import H1 from '../headers/H1'
import SectionSetUp from '../sections/SectionSetUp'
function HeroSection() {
    // sections will be set up with connect then manage then grow
  return (
    <SectionSetUp styles='' bg='py-20 gap-3'>
      {/* Hero Section */}
      <div className='flex flex-wrap gap-12 items-center justify-evenly w-full px-8'>
        <div className='flex flex-col justify-center items-start'>
          <H1 styles="font-bold text-primary">Create An Online Presence</H1>
          <p className="text-gray-400 text-lg font-bold mb-4">Maximize your real estate business with our comprehensive solutions.</p>
          <Button2 styles="bg-primary text-white text-3xl font-bold hover:bg-primary/[0.9] ">Get Started</Button2>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <img className='w-[350px] h-[350px] rounded-xl opacity-80 shadow-lg' src={'https://cnkmivpinfagdnsaxcdk.supabase.co/storage/v1/object/public/profilePics/public/Landing%20Page%20items/landingPageHero.jpg'} alt='hero img' />
          <div className='w-full flex justify-end text-primary text-sm mt-1'><a href="https://www.freepik.com/free-vector/illustrated-creative-realtor-assistance_9906723.htm#fromView=search&page=1&position=4&uuid=024bf9ba-5f43-4bf5-abfa-d7c438f2aeb0" target='_blank' rel='noreferrer'>Image by freepik</a></div>
        </div>
        
      </div>


    </SectionSetUp>
  )
}

export default HeroSection