import { Alert, AlertIcon, AlertTitle, Avatar, Textarea } from '@chakra-ui/react'
import { IconHome, IconMail, IconPhone } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import supabase from '../../supabase/connect'
import Button2 from '../buttons/Button'

function LeftContactSide({ userData, user }) {
    const [alert, setAlert] = useState('hidden')
    const [note,setNote] = useState('')
    const [notes, setNotes] = useState([])
    const copyClipboard = async (str) => {
        try {
            await navigator.clipboard.writeText(str)
            setAlert('fixed')
            setTimeout(() => {
                setAlert('hidden');
            }, 2000)
        } catch (err) {
            console.error('Failed to copy text: ', err)
        }
    }
    const getNotes = async () => {
        let { data: contact_note, error } = await supabase
        .from('contact_note')
        .select('*')
        .eq('contact_id', userData.user.id)
        setNotes(contact_note)
        if (error) {
            console.log(error)
        }
    }
    const deleteNote = async(id) => {
        const { error } = await supabase
        .from('contact_note')
        .delete()
        .eq('id', id)
        if (error) {
            console.log(error)
        }
        getNotes()
    }
    const addNote = async (e) => {
        e.preventDefault()
        if (note === '') {
            return
        }
        const { data, error } = await supabase
        .from('contact_note')
        .insert([
            { note: note, contact_id: userData.user.id, user_id: user },
        ])
        if (error) {
            console.log(error)
        }
        getNotes()
        setNote('')
        
    }
    useEffect(() => {
        getNotes()
    },[])
  return (
        <div className=' max-w-[800px] max-h-[800px] flex flex-col gap-2 items-center justify-start p-3 w-full overflow-y-auto'>
            <div className={`${alert} top-16 right-[1rem] rounded-lg shadow overflow-hidden animate-pulse`}>
                <Alert status='success' >
                    <AlertIcon />
                    <AlertTitle>Copied!</AlertTitle>
                </Alert>
            </div>
            <div className=' flex justify-center items-center mt-3'>
                <Avatar size='lg' name={`${userData.user.first_name} ${userData.user.last_name}`} />
            </div>
            <div className='w-full flex flex-col justify-center items-center gap-1 text-md '>
                <span className='capitalize bg-gray-100 px-3 font-bold border rounded-lg'>{userData.user.first_name}</span>
                <span className='capitalize bg-gray-100 px-3 font-bold border rounded-lg'>{userData.user.last_name}</span>
            </div>
            <div className='w-[70%] h-[2px] bg-primary my-3'>
            </div>
            <div className=' flex flex-col justify-center items-start gap-2 text-md p-3 '>
                <div className='flex  gap-2 items-center'>
                    <span className='font-bold flex gap-1 text-sm items-center '>Email <IconMail /></span>
                    <span onClick={() => copyClipboard(userData.user.email)} className='underline text-primary cursor-pointer'>{userData.user.email}</span>
                </div>
                <div className='flex gap-2 items-center'>
                    <span className='font-bold flex gap-1 text-sm items-center'>Phone # <IconPhone /></span>
                    <span className=''>{userData.user.phone}</span>
                </div>
                <div className='flex  gap-2 items-center'>
                    <span className='font-bold flex gap-1 text-sm items-center '>Open House <IconHome /></span>
                    <span className='  '>{userData.address}</span>
                </div>
            </div>
            <div className='w-[70%] h-[2px] bg-primary my-3'></div>
            <span className='w-full text-start font-extrabold text-xl'>Notes</span>
            <form onSubmit={addNote} className='w-full px-6 flex flex-col'>
                <Textarea value={note} onChange={(e) => setNote(e.target.value)} placeholder='Add your note here....'/>
                <Button2 onClick={addNote} styles='text-white hover:bg-primary/[0.9] mt-1' color='bg-primary'>Add Note</Button2>
            </form>
            {
                notes ? notes.map(each => {
                    return (
                        <div key={each.id} className='bg-gray-100 font-bold p-4 w-full rounded-lg shadow mt-1'>
                            <div className='w-full flex items-center justify-end'>
                                <button onClick={() => deleteNote(each.id)} className='w-[20px] h-[5px]  rounded-full bg-red shadow'></button>
                            </div>
                            <p>
                                {each.note}
                            </p>
                        </div>
                    )
                }) : <span>Loading...</span>
            }
        </div>
  )
}

export default LeftContactSide