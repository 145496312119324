import { useEffect, useState } from "react"
import supabase from "../supabase/connect"
import ListingList from "../components/listsComp/ListingList"
import Loader from "../components/loaders/Loader"

export default function ListingCheckForContactForm () {
 const [listingInfo, setListingInfo] = useState('')
 const [user, setUser] = useState('')
    const getListings = async () => {
        let { data: { user }, error: user_err } = await supabase.auth.getUser()
        if (user_err){
            console.error(user_err)
            return
        }
        let { data: listing, error } = await supabase
        .from('listing')
        .select('*')
        .eq('user_id', user.id)
        if (error){
            console.error(error)
            return
        }
        setListingInfo(listing)
        setUser(user)
    }
    useEffect(() => {
        getListings()
    }, [])
    if (!listingInfo){ 
        return <Loader />
    }
    return (
        <section className="w-full min-h-fit-screen p-3 flex flex-col justify-center items-center">
            <h1 className="my-3 text-primary font-extrabold text-lg text-center uppercase">Choose a listing to create a form for</h1>
            <ListingList userData={listingInfo}/>
        </section>
    )
}