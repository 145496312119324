import { Button, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { IconKey, IconMail, IconUser, IconUserBolt } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Auth from '../../auth/Auth'
import supabase from '../../supabase/connect'
import FormBTN from '../buttons/FormBTN'

function NewUserForm() {
    const [email, setEmail] = useState('')
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [show, setShow] = React.useState(false)
    const [user, setUser] = useState()
    const [userData, setUserData] = useState()
    const userInfo = async () => {
        const info = await Auth.userInfo()
        if (info){
            window.location.replace('/uer/dashboard')
            return
        }
    }

    const handleClick = () => setShow(!show)
    const createUser = async (e) => {
        e.preventDefault()
        if(password !== rePassword){
            setErrorMessage('Passwords must Match')
            setPassword('')
            setRePassword('')
            setEmail('')
            setFirst('')
            setLast('')
            return
        }
        let { data, error } = await supabase.auth.signUp({
            email: email,
            password: password
          })
          if (error){
            setErrorMessage(error.message)
            return
          }
          const { data: upsert, error:inserted_err } = await supabase
          .from('User')
          .upsert({ user_id: data.user.id, email:data.user.email, first_name: first, last_name: last })
        if (inserted_err){
            setErrorMessage(inserted_err.message)
            return
          }
        window.location.replace(`/user/dashboard`)
    }
    useEffect(() => {
      userInfo()
    }, [])
  return (
    <form onSubmit={createUser} className='bg-white w-full max-w-[400px] mx-auto rounded-lg p-6 shadow shadow-dark-600 flex flex-col justify-center items-center gap-3 relative'>
        <h1 className='text-secondary font-bold text-3xl'>Sign Up</h1>
        <div className='flex gap-2 w-full'>
        <InputGroup size='md'>
          <Input
            type='text'
            placeholder='Enter first name'
            onChange={(e) => setFirst(e.target.value)}
          />
          <InputLeftElement >
              <IconUserBolt />
          </InputLeftElement>
        </InputGroup>
        <InputGroup size='md'>
          <Input
            type='text'
            placeholder='Enter last name'
            onChange={(e) => setLast(e.target.value)}
          />
          <InputLeftElement >
              <IconUser />
          </InputLeftElement>
        </InputGroup>
        </div>
        <InputGroup size='md'>
          <Input
            type='email'
            placeholder='Enter Email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputLeftElement >
              <IconMail />
          </InputLeftElement>
        </InputGroup>
        <InputGroup size='md'>
          <Input
            pr='4.5rem'
            type={show ? 'text' : 'password'}
            placeholder='Enter password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputLeftElement>
            <IconKey />
          </InputLeftElement>
          <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='sm' onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
        <InputGroup size='md'>
          <Input
            pr='4.5rem'
            type={show ? 'text' : 'password'}
            placeholder='Retype password'
            onChange={(e) => setRePassword(e.target.value)}
          />
          <InputLeftElement>
            <IconKey />
          </InputLeftElement>
          <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='sm' onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
        {
            errorMessage ? <span className='text-red font-bold w-full text-right'>{errorMessage}</span> : <></>
        }
        <FormBTN styles='bg-primary hover:bg-primary/[.9] text-white text-xl'>Sign In</FormBTN>
        <Link to='/login' className='absolute bottom-[-25px] right-0 font-bold text-sm text-primary'>Already have an account? click here.</Link>
    </form>
  )
}

export default NewUserForm