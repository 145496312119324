import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { IconCalendar, IconWriting } from "@tabler/icons-react";
import Button2 from "../buttons/Button";
import { useState } from "react";
import supabase from "../../supabase/connect";

export default function EventForm ({ user }) {
    const [date, setDate] = useState('')
    const [title, setTitle] = useState('')
    const addMeeting = async() => {
        if(title==='' || date === '') {
            alert('Enter valid date and title')
            return
        }
        const { data, error } = await supabase
        .from('meeting')
        .insert([
          { date: date, title: title, user_id: user },
        ])
        if (error){
            console.error(error)
        }
        setDate('')
        setTitle('')
        window.location.reload()
    }
    return (
    <div className='flex flex-col gap-2 mt-3 bg-white p-4 rounded-lg shadow  '>
        <InputGroup>
          <InputLeftAddon><IconWriting className='text-primary' /></InputLeftAddon>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} type='text' placeholder='Write the meeting title...' />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon><IconCalendar className='text-primary' /></InputLeftAddon>
          <Input value={date} onChange={(e) => setDate(e.target.value)} type='datetime-local' placeholder='enter Date' />
        </InputGroup>
        <Button2 onClick={addMeeting} styles={'hover:bg-primary/[0.9] text-white'} color={'bg-primary'}>Add Meeting</Button2>
    </div>
    )
}